.hero {
    position: relative;
    width: 100%;
    margin-bottom: 0.5rem;
    overflow: hidden;

    .hero-image {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
        }
    }

    .hero-banner-content {
        position: absolute;
        top: 50%;
        left: 10rem;
        width: 100%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        transform: translateY(-50%);

        .title {
            color: $white;
            font-size: 3rem;
            font-weight: 500;
            font-family: $headings-font-family;
        }

        .subtitle {
            color: $white;
            font-size: 1.5rem;
            margin-bottom: 2rem;
        }

        .btn {
            padding: 1rem 5rem;
            text-transform: uppercase;
            transition: opacity 0.3s ease;
        }
    }

    &.hero-page {
        .hero-banner-content {
            width: 100%;
            left: 0;
            text-align: center;

            .title {
                font-size: 14rem;
                text-transform: uppercase;
                margin-bottom: 1rem;
            }
        }
    }

    @include media-breakpoint-down($main-breakpoint) {
        height: 80vh;

        .hero-image {
            position: absolute;
            width: 100%;
            height: 100%;
            text-align: center;

            img {
                position: absolute;
                left: 50%;
                top: 50%;
                width: auto;
                height: 100%;
                max-width: none;
                transform: translate(-50%, -50%);
            }
        }

        .hero-banner-content {
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;

            .title {
                margin-bottom: 2rem;
                font-size: 2rem;
                text-shadow: 1px 1px 9px rgba(0, 0, 0, 0.75);
            }

            .subtitle {
                display: none;
            }

            .btn {
                padding: 1rem 3rem;
            }
        }

        &.hero-page {
            .hero-banner-content {
                left: 50%;
                transform: translate(-50%, -50%);

                .title {
                    font-size: 5rem;
                }
            }
        }
    }
}

.home-usp {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .home-usp-item {
        display: block;
        height: 9rem;
        width: 24%;
        background-color: $home-usp-background-color;
        box-shadow: $home-usp-box-shadow;
        color: $home-usp-text-color;
        font-size: 1.2rem;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        line-height: 9rem;
        transition: background-color 0.3s ease;

        &:hover {
            text-decoration: none;
            background-color: $secondary;
        }
    }

    @include media-breakpoint-down($main-breakpoint) {
        display: block;

        .home-usp-item {
            width: 100%;
            margin-bottom: 0.5rem;
        }
    }
}

.home-products {
    margin: 1rem 0;
    padding-bottom: 4rem;

    .home-title {
        padding: 5rem 0;
        font-family: $headings-font-family;
        font-size: $h2-font-size;
        text-align: center;
    }

    .product-item {
        padding: 1.5rem;
        background-color: $white;
        text-align: center;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
        transition: transform 0.3s ease, box-shadow 0.3s ease;

        .link:hover {
            text-decoration: none;
        }

        .product-item-name {
            margin: 1rem 0;
            min-height: 4.5rem;
            font-weight: 500;
            text-transform: uppercase;
        }

        .price-box {
            font-size: $h5-font-size;
            font-weight: 200;
        }

        @include media-breakpoint-up($main-breakpoint) {
            &:hover {
                transform: translateY(-0.75rem) scale(1.1);
                box-shadow: 5px 5px 33px 0px rgba(0, 0, 0, 0.2);
            }
        }

        @include media-breakpoint-down($main-breakpoint) {
            img {
                width: 100%;
            }
        }
    }

    @include media-breakpoint-down($main-breakpoint) {
        padding-bottom: 4rem;
    }
}

/* IG-66 > If Content is used!
.cms-index-index {
    .page-main {
        margin-top: 3rem;

        .readmore-wrapper {
            display: none;
        }

        @include media-breakpoint-down($main-breakpoint) {
            .readmore-wrapper {
                display: block;

                .more {
                    display: block;
                }
                .less {
                    display: none;
                }
            }

            .columns .column {
                position: relative;
                margin-bottom: 1.071em;
                height: 6em;
                overflow: hidden;

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 3em;
                    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, #fff 100%);
                    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, #fff 100%);
                    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, #fff 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
                }
            }
            &.open {
                .columns .column {
                    height: auto;

                    &:after {
                        display: none;
                    }
                }
                .readmore-wrapper {
                    .more {
                        display: none;
                    }
                    .less {
                        display: block;
                    }
                }
            }
        }
    }
}
*/
.block-instagram {
    padding-top: 2rem;
    padding-bottom: 2rem;

    h1 {
        padding-top: 3rem;
        padding-bottom: 1rem;
        text-align: center;

        img {
            height: 4.2rem;
            width: auto;
        }

        @include media-breakpoint-down($main-breakpoint) {
            font-size: $h2-font-size;
        }
    }

    .instagram-subtitle {
        padding-bottom: 3rem;
        font-family: $font-family-sans-serif;
        font-weight: 300;
        text-align: center;
    }

    .instagram-wrapper {
        display: flex;
        justify-content: space-between;

        a {
            display: block;
            width: 32%;
            transition: opacity 0.3s ease;

            &:hover {
                opacity: 0.5;
            }
        }

        @include media-breakpoint-down($main-breakpoint) {
            display: block;

            a {
                width: 100%;
                padding-bottom: 1.5rem;
            }
        }
    }
}

.offcanvas-heading {
    padding: 1rem 2rem;
    border-bottom: 1px solid $black;

    .title {
        font-family: $font-family-sans-serif;
    }

    .close {
        color: $black;
        opacity: 1;
    }
}

.navbar {
    margin-bottom: 1rem;
    background: $nav-background;

    @include media-breakpoint-down($main-breakpoint) {
        margin-bottom: 0;
        background: $white !important;

        .navbar-brand {
            img {
                max-height: 2rem;
            }
        }

        .navbar-nav {
            padding: 1rem 0;
        }
    }
}

.navbar-collapse {
    .offcanvas-heading {
        padding: 1rem 15px;
    }
}

.navbar-light .navbar-toggler {
    // padding-top: 0.6rem;
    // padding-right: 1rem;
    border: none;

    .line {
        height: 3px;
        width: 1.5rem;
        border-radius: 0.25rem;
        margin-bottom: 0.6rem;
        background-color: $primary;
    }

    @include media-breakpoint-down($main-breakpoint) {
        //padding-right: 1.5rem;
    }
}

.navbar-header-right,
.navbar-header-left {
    height: 2rem;

    .btn {
        font-size: 1.2rem;

        &.navbar-wishlist {
            padding-right: 0.25rem;
        }
    }

    .search-toggle {
        font-size: 1.2rem;
    }
}

.navbar-header-left,
.navbar-header-right {
    width: 30%;
}

.navbar-header-right {
    text-align: right;
}

.navbar-header-center {
    width: 40%;
    text-align: center;
}

footer.footer {
    padding: $padding-y 0;
    padding-bottom: 0;
    background-color: $white;
}

.footer-blocks {
    color: $footer-text-color;
    padding: 3rem 0;
    background: $footer-background;

    @include media-breakpoint-down($main-breakpoint) {
        background: $footer-background-desktop;
    }

    .footer-logo {
        img {
            filter: $footer-filter;
        }
    }
}

.footer-block {
    @include media-breakpoint-down($main-breakpoint) {
        margin-bottom: 1rem;
    }

    .block-title {
        margin-bottom: 1rem;
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        color: $headings-color;
        @include font-size($h4-font-size);

        &.label-collapse {
            &:after {
                @include svg-icon(1rem, 1rem, $icon-minus, $white);
            }

            &.collapsed {
                &:after {
                    @include svg-icon(1rem, 1rem, $icon-plus, $white);
                }
            }
        }

        @include media-breakpoint-up($main-breakpoint) {
            margin-bottom: 2rem;
        }
    }

    .block-content {
        ul {
            padding: 0;
            list-style-type: none;

            li {
                padding: 0.25rem 0;

                @include media-breakpoint-up($main-breakpoint) {
                    padding: 0.5rem 0;
                }
            }
        }

        a:not(.btn) {
            color: $footer-text-color;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -0.1rem;
                width: 100%;
                height: 1px;
                background-color: $black;
                max-width: 0;
                transition: max-width 0.3s ease;
            }

            &:hover {
                text-decoration: none;

                &::after {
                    max-width: 100%;
                }
            }
        }
    }
}

.footer-social {
    filter: $footer-filter;
    padding: 2rem 0;
    font-size: 2rem;
    text-align: center;

    a {
        display: inline-block;
        margin: 0 0.5rem;
        transition: opacity 0.3s ease;

        &:hover {
            opacity: 0.5;
        }
    }
}

.footer-iso {
    text-align: center;

    @include media-breakpoint-down($main-breakpoint) {
        display: none;
    }

    a {
        color: $footer-text-color;
    }
}

.block-payments {
    margin-top: 4rem;
    text-align: center;
    filter: grayscale(1);

    .block-title {
        display: none;
    }

    img {
        height: 1.25rem;
        margin: 0 0.5rem;
    }

    @include media-breakpoint-down($main-breakpoint) {
        display: none;
    }
}

.footer-logo {
    @include media-breakpoint-down($main-breakpoint) {
        padding: 3rem 0;
        text-align: center;

        img {
            max-height: 3rem;
        }
    }
}

.footer-bottom-wrapper {
    width: 100%;
    padding: 1rem 0;
    color: $white;
    background-color: $black;

    .copyright {
        font-size: 1rem;

        @include media-breakpoint-down($main-breakpoint) {
            display: block;
            text-align: center;
        }
    }

    .made-by {
        font-size: 0.8rem;
        float: right;

        @include media-breakpoint-down($main-breakpoint) {
            display: none;
        }
    }
}

header {
    margin-bottom: map-get($spacers, 3);

    @include media-breakpoint-down($main-breakpoint) {
        margin-bottom: 0;
    }

    .top-wrapper {
        margin-bottom: map-get($spacers, 3);
        padding: 0;

        @include media-breakpoint-down($main-breakpoint) {
            display: none;
        }
    }

    .header {
        @include media-breakpoint-down($main-breakpoint) {
            display: none;
        }
    }
}

.breadcrumbs-wrapper {
    margin-top: $spacer;
}

.header-icons {
    font-size: 1.5rem;

    .icon {
        width: $icon-header__width;
        height: $icon-header__height;

        &:before {
            width: $icon-header__width;
            height: $icon-header__height;
        }
    }

    .btn {
        font-size: 1.5rem;
        transition: opacity 0.3s ease;

        &:hover {
            opacity: 0.5;
        }
    }
}

.logo {
    display: block;
    padding: $logo-padding;
    text-align: center;

    img {
        max-height: $logo-max-height;
        width: auto;
    }
}

.language-placeholder {
    display: inline-block;
    padding-left: 0.5rem;
    transition: opacity 0.3s ease;
    cursor: pointer;

    img {
        display: inline-block;
        margin-right: 0.5rem;
    }

    &:hover {
        opacity: 0.5;
    }
}

.back-to-top {
    height: 3rem;
    width: 3rem;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    background-color: $primary;
    color: $white;
    font-size: 1.2rem;
    line-height: 3rem;
    text-align: center;
    opacity: 0;
    transform: translateX(1rem);
    box-shadow: 3px 10px 27px -6px rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease, transform 0.3s ease;
    pointer-events: none;
    z-index: 100;

    .arrow-up {
        &:after {
            @include svg-icon(1.2rem, 1.2rem, $icon-arrow-up, $white);
        }
    }


    &.show {
        opacity: 1;
        transform: none;
        pointer-events: auto;
        cursor: pointer;
    }

    &:hover {
        transform: scale(1.1);
    }
}

.catalog-category-view:not(.page-with-filter) {
    @include media-breakpoint-down($main-breakpoint) {
        .sidebar {
            display: none;
        }
    }
}

.page-with-filter {
    @include media-breakpoint-down($main-breakpoint) {
        .page-title {
            font-size: 2rem;
        }

        .category-description {
            display: none;
        }

        .category-cms {
            .btn-primary {
                padding-left: 0;
                background-color: initial;
                border: initial;
                color: $black;

                span::before {
                    margin-right: 0.25rem;
                    @include svg-icon(1rem, 1rem, $icon-question, $black);
                }
            }
        }
    }
}

.category-view {
    padding-bottom: 2rem;

    @include media-breakpoint-down($main-breakpoint) {
        padding-bottom: 1rem;
    }
}

.category-description,
.category-seo-description {
    margin-bottom: map-get($spacers, 1);

    .readmore-wrapper {
        display: none;
    }

    @include media-breakpoint-down($main-breakpoint) {
        .page-content {
            position: relative;

            &.collapsable {
                margin-bottom: map-get($spacers, 2);
                max-height: 5rem;
                overflow: hidden;
                @include transition(max-height 200ms ease-in-out);

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 7rem;
                    @include gradient-y(transparent, $white);
                }
            }
        }

        .readmore-wrapper {
            display: block;

            .showmore {
                color: $body-color;

                span {
                    font-size: $h6-font-size;
                }

                i {
                    margin-right: map-get($spacers, 2);
                    color: $primary;
                }
            }

            .more {
                display: block;
            }

            .less {
                display: none;
            }
        }

        &.open {
            .page-content {
                max-height: none;
                @include transition(max-height 200ms ease-in-out);

                &:after {
                    display: none;
                }
            }

            .readmore-wrapper {
                .more {
                    display: none;
                }

                .less {
                    display: block;
                }
            }
        }
    }
}

.seo-category-description {
    padding: 2rem 0;
}

.sidebar-main {
    .page-title {
        font-size: 1.5rem;
    }

    .block.filter {
        .block-subtitle {
            font-family: $font-family-base;
            font-weight: 700;
        }

        .filter-subtitle {
            display: none;
        }

        .amshopby-filter-current {
            .amshopby-filter-name {
                margin-right: 0.5rem;
            }
        }

        .filter-options {

            .item {
                padding: 0.25rem 0;

                a:not(.btn) {
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: -0.1rem;
                        width: 100%;
                        height: 1px;
                        background-color: $black;
                        max-width: 0;
                        transition: max-width 0.3s ease;
                    }

                    &:hover {
                        text-decoration: none;

                        &::after {
                            max-width: 100%;
                        }
                    }
                }

                input[type="radio"] {
                    display: none;
                }
            }
        }

        .ui-slider-range,
        .ui-slider-handle {
            background-color: $black;
        }

        .ui-slider-horizontal {
            background-color: initial;
            position: relative;
        }

        .ui-slider-range {
            height: 3px;
            top: 1px;
        }

        .ui-slider-handle {
            width: 16px;
            height: 16px;
            transition: background-color 0.3s ease;
            cursor: pointer;
            position: absolute;
            transform: translateY(-50%);

            &:hover {
                background-color: $gray-800;
            }
        }

        .filter-options-item {
            margin: 2rem 0;

            .filter-options-title {
                margin-bottom: 0.5rem;
                font-size: 1.25rem;
                font-weight: 600;
            }

            &:first-of-type {
                margin-top: 0;
            }
        }
    }
}

.categories-grid {
    .item {
        display: block;
        position: relative;
        margin-bottom: 1rem;
        overflow: hidden;
        min-height: 10rem;

        img {
            display: block;
            width: 100%;
            transition: transform 0.6s ease;
        }

        .category-name {
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 1rem;
            background-color: $category-background-color;
            color: $category-text-color;
            transition: color 0.6s ease, background-color 0.6s ease;

            .subtitle {
                margin-bottom: 0.5rem;
                text-transform: uppercase;
            }
        }

        &:hover {
            img {
                transform: scale(1.1);
            }

            .category-name {
                background-color: $secondary;
                color: $category-text-hover-color;
            }
        }
    }
}

.toolbar {
    .toolbar-flex {
        @include media-breakpoint-down(xs) {
            flex-direction: column;
        }
    }
}

.toolbar-footer {
    .btn-layered {
        display: none;
    }
}

.pages-items {
    .item {
        +.item {
            margin-left: 0.5rem;
        }
    }

    .page-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        height: 3rem;
        background: $secondary;
        border-color: $secondary;
        border-radius: 50% !important;

        &.next,
        &.previous {
            span {
                display: none;
            }
        }

        &.next {
            &:before {
                @include svg-icon(1.3rem, 1.3rem, $icon-angle-right, $black);
            }
        }

        &.previous {
            &:before {
                @include svg-icon(1.3rem, 1.3rem, $icon-angle-left, $black);
            }
        }

        @include media-breakpoint-down($main-breakpoint) {
            width: 2rem;
            height: 2rem;
        }
    }

    @include media-breakpoint-down($main-breakpoint) {
        display: flex;;
        justify-content: center;
    }
}

.toolbar {
    .toolbar-limiter {
        @include media-breakpoint-down($main-breakpoint) {
            display: none;
        }
    }
}

#category-info-wrapper {
    .modal-dialog {
        max-width: 1000px;
    }
}

.products-grid {
    .product-col {}

    .product-item {

        &-photo,
        img,
        .product-image-container,
        .product-image-wrapper {
            display: block;
            width: 100%;
        }

        &-name {
            min-height: 2.5rem;
            text-transform: uppercase;

            .product-item-link {
                font-weight: 500;
            }
        }

        &-extra {
            margin-top: 0.5rem;
            font-size: $font-size-sm;

            div+div {
                margin-top: 0.5rem;
            }
        }

        .price-box {
            .price-label {
                display: none;
            }

            .price {
                font-size: 1.5rem;
                font-weight: 200;
            }

            .old-price {
                .price {
                    font-size: 1.2rem;
                    color: $old-price-text-color;
                    text-decoration: line-through;

                }
            }
        }

        &-actions {

            .actions-primary,
            .actions-secondary {
                display: block;
            }

            .towishlist {
                &:before {
                    @include svg-icon(1rem, 1rem, $icon-wishlist, $black);
                }
            }

            .tocompare {
                &:before {
                    @include svg-icon(1rem, 1rem, $icon-compare, $black);
                }
            }
        }
    }
}

.toolbar-top {
    @include media-breakpoint-up($main-breakpoint) {
        display: none;
    }

    .btn:not(.close) {
        width: calc(50vw - 18px);
    }

    .toolbar-limiter,
    .modes,
    .input-group-append {
        display: none;
    }
}

.toolbar {
    .pages {
        display: none;
    }

    .toolbar-amount {}
}

.toolbar-footer {
    .pages {
        display: block;
    }
}

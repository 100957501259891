.btn-primary {
    background: rgba($secondary, 0.5);
    border: none;
    color: $black;
}

@mixin btn-primary {
    background: rgba($secondary, 0.5);
    border: none;
    color: $black;
}
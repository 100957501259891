.navbar-minicart {
    position: relative;
    padding-right: 0;

    .counter {
        position: absolute;
        bottom: 0.2rem;
        right:-0.5rem;
        width: 1.2rem;
        height: 1.2rem;
        background: $black;
        color: $white;
        font-size: 0.6rem;
        text-align: center;
        line-height: 1.2rem;
        border-radius: 50%;
        z-index: 4;

        &.empty {
            display: none;
        }
    }
}

.header-minicart {
    position: relative;

    .text {
        display: none;
    }
    .counter {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 1.5rem;
        height: 1.5rem;
        background: $black;
        color: $white;
        font-size: 0.7rem;
        text-align: center;
        line-height: 1.5rem;
        border-radius: 50%;
        z-index: 4;

        &.empty {
            display: none;
        }
    }
}

.offcanvas-minicart {
    .minicart-items-wrapper {
        height: auto !important;
    }
}

.catalog-product-view {
    .page-title {
        font-size: 1.5rem;
        font-family: $font-family-sans-serif;
        font-weight: 500;
    }

    .short-description {
        max-width: 500px;
        padding-top: 1rem;

        a {
            text-decoration: underline;
        }
    }

    .product-info-main {
        .price-box {
            display: flex;
            flex-wrap: wrap;
            font-size: 3.5rem;
            font-weight: 200;

            .price-label {
                display: none;
            }

            .sly-old-price {
                .price-label {
                    display: none;
                }
            }

            .special-price,
            .normal-price {
                order: 2;
            }

            .old-price {
                order: 1;
                flex-basis: 35%;
                font-size: 1.5rem;
                color: $secondary;
                text-decoration: line-through;
                float: left;
            }

            @include media-breakpoint-down($main-breakpoint) {
                width: 54%;
            }
        }

        .swatch-opt .swatch-attribute-options .swatch-option.disabled:after {
            content: '';
            position: absolute;
            left: 4px;
            right: 4px;
            top: 4px;
            bottom: 4px;
            opacity: 1;
            background: linear-gradient(to left top, #D4AAA0 0%, #D4AAA0 42%, #D4AAA0 43%, #D4AAA0 46%, #FFF 47%, #FFF 53%, #D4AAA0 54%, #D4AAA0 57%, #D4AAA0 58%, #D4AAA0 100%);
        }
    }

    .product-attribute {
        .label {
            font-weight: $font-weight-bold;
        }
    }

    .swatch-attribute-label {
        font-weight: 200;
    }

    .product-info-price {
        display: flex;
        align-items: flex-end;
        padding-top: 1rem;

        .product-extra-info {
            margin-left: 1rem;
        }

        @include media-breakpoint-between(sm, md) {
            display: block;
            padding-bottom: 1rem;
        }
    }

    .box-tocart {
        .form-inline {
            display: block;
        }

        .form-group {
            display: flex;
            flex: 0 0 auto;
            flex-flow: row wrap;
            align-items: center;
        }

        .form-group.qty {
            display: block;

            label {
                display: block;
                margin-bottom: 0.5rem;
                font-weight: 200;
            }
        }

        .spinbox {
            .spinbox-input {
                max-width: $spinbox-width;
                text-align: center;
            }
        }

        .primary.tocart {
            margin: 1rem 0;
            padding: 1rem 3rem;
            text-transform: uppercase;
            box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.2);
        }

        .towishlist {
            padding: 0.7rem 1rem;
        }
    }

    .swatch-option {
        width: 70px !important;
        height: 70px !important;
        background-size: cover !important;
        transition: transform 0.3s ease;

        &:not(.disabled):hover {
            transform: scale(1.1);
            box-shadow: none;
        }
    }

    .product-addto-links {
        margin-top: map-get($spacers, 3);
    }

    .towishlist {
        span {
            display: none;
        }

        &::after {
            @include svg-icon(1rem, 1rem, $icon-wishlist, $black);
            transition: opacity 0.3s ease;
        }


        &:hover {
            text-decoration: none;
            opacity: 0.5;
        }
    }

    .attribute-collapse {
        margin-bottom: 1rem;

        a {
            text-decoration: underline;
        }

        .label {
            padding: 1.5rem;
            background-color: $pdp-accordion-background-color;
            color: $pdp-accordion-text-color;
            font-weight: 300;
        }

        .label-collapse {
            &:after {
                float: right;
                @include svg-icon(1rem, 1rem, $icon-minus, $pdp-accordion-icon-color);
            }

            &.collapsed {
                &:after {
                    @include svg-icon(1rem, 1rem, $icon-plus, $pdp-accordion-icon-color);
                }
            }
        }

        .value {
            padding: 1.5rem;
        }
    }

    .additional-attributes {
        dl {
            @include make-col-ready();

            .item {
                @include make-row();
            }

            dt {
                @include make-col(5);
            }

            dd {
                margin: 0;
                @include make-col(7);
            }
        }
    }

    .product-details-wrapper {
        padding: map-get($spacers, 5) 0;
        padding-bottom: 1.5rem;
        margin-bottom: map-get($spacers, 4);
    }

    .block.related {
        .block-title {
            margin: 2rem 0;
        }
    }

    .fotorama {
        .fotorama__thumb-border {
            border: 1px solid transparent;
        }

        .fotorama__arr {
            &:not(.fotorama__arr--prev) {
                .fotorama__arr__arr {
                    background: unset;
                    @include svg-icon(3rem, 3rem, $icon-angle-right, $black);
                }
            }

            .fotorama__arr__arr {
                background: unset;
                @include svg-icon(3rem, 3rem, $icon-angle-left, $black);
            }
        }
    }

    .block-product-link-inline {
        a {
            display: inline-block;
        }
    }
}



.contact-index-index {
    .page-title-wrapper {
        display: none;
    }

    .contact-title {
        padding-bottom: 2rem;

        span {
            position: relative;

            img {
                height: 2.5rem;
            }

            &::after {
                content: '';
                height: 100%;
                width: 100%;
                position: absolute;
                bottom: -75%;
                right: 0;
                background-color: $secondary;
                z-index: -1;
            }
        }
    }

    .page-main {
        padding-top: 3rem;
    }

    textarea {
        height: auto;
    }

    input {
        min-height: 3rem;
    }

    .submit {
        padding: 1rem 5rem;
    }
}

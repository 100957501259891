.checkout-cart-index {
    .cart-empty {
        margin-top: map-get($spacers, 4);
        margin-bottom: map-get($spacers, 4);
        padding: map-get($spacers, 4) 0 map-get($spacers, 3) 0;
        border: ($border-width * 2) solid $gray-200;
        text-align: center;
    }
}

.cart-container {
    padding-bottom: 4rem;

    .cart-block {
        border: none;

            @include media-breakpoint-down($main-breakpoint) {
                margin-top: 2rem;
            }

        .block-title,
        .page-title {
            font-size: 2rem;
        }

        .page-title-wrapper .page-title {
            margin-bottom: 0;
        }

        .page-title-wrapper,
        h2.summary {
            padding-bottom: 1.5rem;
            border-bottom: solid 1px $black;
        }

        .action.update {
            padding: 0.5rem 3rem;
            color: $white;
            background-color: $black;
            border: none;

            &::before {
                display: none;
            }
        }

        .action.continue {
            color: $black;
            font-weight: 200;

            &::before {
                color: $black;
                margin-right: 0.5rem;
            }
        }
    }

    button.checkout {
        border-radius: 0;
    }

    .cart {
        .item-info {
            .product-item-name {
                font-size: 1.2rem;
                font-family: $headings-font-family;
            }

            .cart-price-block {
                font-weight: 200;
            }

            .subtotal {
                display: none;
            }
        }
    }

    .block-payments {
        margin-top: 2rem;
        text-align: left;

        .block-title {
            display: block;
            margin-bottom: 0.5rem;
            font-size: 1rem;
            font-weight: 200;
        }

        img {
            margin-left: 0;
            margin-bottom: 1rem;
        }
    }

    .data.table.totals {
        * {
            font-size: 1rem !important;
            font-weight: 200 !important;
        }
    }
}

.block {
    &.crosssell {
        .block-title {
            display: block;
            margin-bottom: $headings-margin-bottom * 2;
            @include font-size($h3-font-size);
        }
    }
}

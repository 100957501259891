//
//  Helpers
//  ____________________________________________

@mixin _amblog-toggle {
    &:before {
        background: $amblog-dropdown-arrow__background;
        content: '';
        cursor: pointer;
        display: block;
        height: 12px;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%) rotate(-180deg);
        width: 20px;
    }

    &.-active:before {
        transform: translateY(-50%) rotate(0);
    }
}

@mixin _amblog-form {
    &.amblog-item {
        padding: $amblog-element-content__padding;
    }

    .amblog-form-container {
        position: relative;
    }

    .amblog-input {
        & {
            border: 1px solid $amblog-input__border-color;
            color: $amblog-input-text__color;
            border-radius: $amblog-button__border-radius;
            font-size: $amblog-font-size__s;
            height: 100%;
            padding: 11px 60px 11px 14px;
            width: 100%;
        }

        &:hover {
            border-color: $amblog-input__hover__border-color;
        }

        &::-webkit-input-placeholder,
        &:-ms-input-placeholder,
        &::-moz-placeholder {
            color: $amblog-placeholder__color;
        }

        &:focus {
            border: 1px solid $amblog-svg-custom-classic__color;
            box-shadow: none;
        }

        &:focus::-webkit-input-placeholder,
        &:focus::-moz-placeholder,
        &:focus:-ms-input-placeholder {
            color: transparent;
        }
    }
}

@mixin _amblog-column-form {
    .input-box {
        flex-direction: column;
    }

    .amblog-input {
        margin-bottom: 20px;
        margin-right: 0;
    }

    .amblog-btn,
    .amblog-input {
        max-width: 100%;
        width: 100%;
    }

    .mage-error[generated='true'] {
        margin-bottom: 10px;
        margin-top: -10px;
        order: 0;
        width: 100%;
        word-break: break-all;
    }
}

@mixin _amblog-button {
    & {
        background: $amblog-button__background;
        border-color: $amblog-button__border-color;
        border-radius: $amblog-button__border-radius;
        box-sizing: border-box;
        color: $amblog-button__default-color;
        display: block;
        font-size: $amblog-button__font-size;
        font-weight: $amblog-button__font-weight;
        line-height: $amblog-button__line-height;
        padding: $amblog-button__padding;
        text-align: center;
        transition: all $amblog-button__transition;
    }

    &:hover {
        background: $amblog-button__hover__background;
        color: $amblog-button__hover__color;
        text-decoration: none;
    }

    &:active {
        background: $amblog-button__active__background;
        color: $amblog-button__active__color;
        text-decoration: none;
    }
}

.checkout-index-index .progress-authetication-wrapper {
    margin-bottom: 2rem;

    .opc-progress-bar>li._complete a {
        @include media-breakpoint-down($main-breakpoint) {
            color: $black;
        }
    }

    .opc-progress-bar>li._active {
        &::before {
            color: $black;
        }

        span {
            border-bottom: 2px solid $secondary;

            @include media-breakpoint-down($main-breakpoint) {
                color: $secondary;
                border: none;
            }
        }
    }
}

.checkout-index-index .form-shipping-address {
    .col-sm-12 {
        padding: 0;
    }
}

.methods-shipping {
    margin-top: 2rem;

    .actions-toolbar {
        margin-top: 2rem;
    }

    input[name="shipping_method"] {
        &:checked {
            accent-color: slategray;
            opacity: .4;
        }
    }
}

.payment-option.comment {
    .field.choice {
        padding-left: 0;
    }

    .payment-option-content {
        display: block !important;
    }
}

#opc-shipping_method {
    margin-top: 2.5rem;
}

#opc-shipping_method #checkout-shipping-method-load .table-checkout-shipping-method .col-price {
    min-width: 100px;
}

.table-checkout-shipping-method {
    .row {
        margin-bottom: 0.25rem;
        cursor: pointer;
        overflow: hidden;

        .col {
            flex-grow: initial;

            &.col-carrier {
                display: none;
            }

            &:nth-child(2) {
                font-weight: 600;
            }

            &:nth-child(3) {
                flex-grow: 1;
            }
        }
    }
}

.checkout-index-index,
.checkout-cart-index {
    .totals-tax-summary {
        display: none;
    }
}

.totals-tax,
.totals-tax-details,
.totals-tax-summary {
    td {
        padding-right: 0;
        text-align: right;
    }
}

.checkout-index-index .opc .checkout-shipping-address .shipping-address-items .shipping-address-item .action:hover {
    color: $white;
}

.checkout-onepage-success {
    .logo {
        margin-top: 3rem;
    }

    .page-main {
        padding-top: 3rem;
        padding-bottom: 3rem;

        @include media-breakpoint-down($main-breakpoint) {
            padding-bottom: 1rem;

            .col-md-8 {
                margin-bottom: 3rem;
            }
        }
    }
}

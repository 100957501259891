.indian_contact-form-view {
    .page-main {
        .page-title-wrapper {
            padding: 1rem 0;
            padding-top: 2rem;
        }

        .form {
            padding-top: 1rem;
            padding-bottom: 2rem;
        }
    }
}

.announcement-bar {
    width: 100%;
    height: 2.5rem;
    line-height: 2.5rem;
    text-align: center;
    background-color: $black;

    a {
        display: block;
        width: 100%;
        height: 2.5rem;
        color: $secondary;
        transition: opacity 0.3s ease;

        &:hover {
            opacity: 0.5;
            text-decoration: none;
        }
    }
}

.navbar {
    @include media-breakpoint-up($main-breakpoint) {
        padding: 0;
    }
}

.navbar-light .navbar-nav {
    @include media-breakpoint-up($main-breakpoint) {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    text-transform: uppercase;

    .nav-item {
        padding: 0.5rem 1rem;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: $white;

            .nav-link {
                color: $black;
            }
        }

        .btn-toggle {
            float: right;
            background-color: $primary;
            border-radius: 50%;
            width: 3rem;
            height: 3rem;
            line-height: 3rem;
            text-align: center;
            color: $white;
            padding: 0;


            &::after {
                @include svg-icon(1.5rem, 1.5rem, $icon-angle-down, $white);
                border: none;
                vertical-align: middle;
            }

            @include media-breakpoint-up($main-breakpoint) {
                display: none;
            }
        }

        @include media-breakpoint-down($main-breakpoint) {
            .nav-link {
                display: inline-block;
            }
        }

        &.show {
            .btn-toggle {
                &::after {
                    content: '\f106';
                }
            }
        }
    }

    .nav-link {
        color: $nav-text-color;

        @include media-breakpoint-down($main-breakpoint) {
            color: $nav-text-color-mobile;
        }
    }

    .dropdown {
        .dropdown-menu {
            padding-top: 1rem;
            border-radius: 0;
            line-height: 1.6;
            border-width: 0;
            text-transform: none;

            a {
                display: block;
                padding-bottom: 0.5rem;
                color: $black;
                font-size: 0.9rem;
                font-weight: 400;

                &.menu-title-link {
                    &::after {
                        content: '';
                        display: block;
                        width: 75%;
                        margin-top: 0.5rem;
                        border-bottom: 1px solid $gray-200;
                    }
                }

                &.menu-link-primary {
                    margin-bottom: 1rem;
                    color: $primary;
                    font-size: 1.1rem;
                    font-weight: 700;
                    transition: opacity 0.3s ease;

                    &:hover {
                        text-decoration: none;
                    }
                }

                @include media-breakpoint-down($main-breakpoint) {
                    color: $gray-700;
                }
            }
        }

        @include media-breakpoint-up($main-breakpoint) {
            &:hover {
                .dropdown-menu {
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 95%;
                    width: 250px;
                    padding: 2rem;
                    border-width: 0 1px 1px 1px;
                }
            }
        }
    }

    @include media-breakpoint-down($main-breakpoint) {
        border-bottom: 1px solid $black;
    }
}

.angle-down {
    @include svg-icon(1rem, 1rem, $icon-angle-down, $gray-700);
}

.icon-instagram {
    @include svg-icon($icon-footer-width, $icon-footer-height, $icon-instagram, $black);
}

.icon-linkedin {
    @include svg-icon($icon-footer-width, $icon-footer-height, $icon-linkedin, $black);
}

.icon-pinterest {
    @include svg-icon($icon-footer-width, $icon-footer-height, $icon-pinterest, $black);
}

.icon-facebook {
    @include svg-icon($icon-footer-width, $icon-footer-height, $icon-facebook, $black);
}

.icon-whatsapp {
    @include svg-icon($icon-footer-width, $icon-footer-height, $icon-whatsapp, $black);
}

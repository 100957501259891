.currency-dropdown {
    display: inline-block;

    .dropdown-menu {
        position: absolute;
        left: 0;
        min-width: 14rem;
        padding: 1rem 2rem;
        border-width: 0;
        border-radius: 0;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.15);
        line-height: 1.6;
        text-transform: none;

        .dropdown-item {
            padding-left: 0;
        }

        .dropdown-title {
            padding-bottom: 1rem;
        }
    }

    @include media-breakpoint-up($main-breakpoint) {
        &:hover {
            .dropdown-menu {
                display: block;
            }
        }
    }
}

.amlocator-index-index {
    .page-title {
        margin-top: 2rem;
        margin-bottom: 1.5rem;
        text-align: left;
        font-size: 2.5rem;

        .decorative-font {
            position: relative;
            z-index: 2;
            font-size: 2.5rem;
            font-weight: 400;

            &::after {
                content: '';
                height: 50%;
                width: 100%;
                position: absolute;
                bottom: 0;
                right: 0;
                background-color: $secondary;
                z-index: -1;
            }

            @include media-breakpoint-down($main-breakpoint) {
                display: block;
            }
        }


        @include media-breakpoint-down($main-breakpoint) {
            text-align: center;
        }
    }

    .amlocator-main-container {
        padding-bottom: map-get($spacers, 4);
    }

    .chosen-container-multi {
        .chosen-search-input {
            font-size: $font-size-base !important;
        }

        ul {
            li {
                font-size: $font-size-base;
            }
        }
    }

    .amlocator-map-container {
        padding-bottom: map-get($spacers, 4);

        .amlocator-store-desc {
            &.-active {
                color: $white;
                background: $black;
            }
        }

        .amlocator-title,
        .amlocator-label {
            font-size: $font-size-base;
            font-weight: 700;
            text-transform: uppercase;
            cursor: default;
        }

        .amlocator-map {
            filter: grayscale(1);
        }

        .gm-style .gm-style-iw-c {
            border-radius: 0;

            .amasty-popup {
                text-align: center;
                line-height: 1.5;
                padding: map-get($spacers, 3);

                .amlocator-title {
                    margin-bottom: map-get($spacers, 3);
                    font-size: $font-size-base;
                    text-transform: uppercase;
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
        }

        .gm-style .gm-style-iw-t::after {
            display: none;
        }

        .amlocator-search-container {
            padding: map-get($spacers, 3);
            text-align: right;

            .amlocator-search-radius {
                text-align: left;

                .amlocator-wrapper {
                    color: $white;
                }
            }

            .btn-primary {
                @include media-breakpoint-down($main-breakpoint) {
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        .amlocator-filters-container {
            padding: map-get($spacers, 3);
            text-align: right;

            .amlocator-title {
                padding-left: 0;
                padding-right: 0;
            }

            .amlocator-attribute-wrapper {
                display: block;
                padding-left: 0;
                padding-right: 0;
                padding-bottom: map-get($spacers, 2);

                .amlocator-label {
                    margin-bottom: map-get($spacers, 2);
                    text-align: left;
                    width: 100%;
                }

                &:nth-of-type(even) {
                    background: none;
                }

                .amlocator-input {
                    width: 100%;

                    .active-result:hover,
                    .highlighted {
                        background: $black;
                        color: $white;
                    }

                    .search-choice {
                        background: $black;
                    }
                }
            }

            .btn-outline,
            .btn-primary {
                @include media-breakpoint-down($main-breakpoint) {
                    width: 100%;
                }
            }
        }

        @include media-breakpoint-down($main-breakpoint) {
            .amlocator-block.-map {
                margin-bottom: map-get($spacers, 3);
            }
        }
    }

    .amlocator-pager-container .pages .current .page,
    .amlocator-pager-container .pages .page:hover {
        background-color: $primary !important;
    }

    .pages-items .page-link {
        border-color: $primary;
    }

    .amlocator-pager-container .pages a.action:hover {
        background-color: $primary !important;
    }
}

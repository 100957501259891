.block-newsletter {
    max-width: 800px;
    margin: 0 auto;
    padding-bottom: 3rem;

    .block-title {
        padding: 2rem 0;
        font-size: 2.25rem;
        text-align: center;
        font-family: $headings-font-family;
        font-weight: 500;

        span {
            position: relative;
            z-index: 2;
            font-size: 2.5rem;

            &::after {
                content: '';
                height: 50%;
                width: 100%;
                position: absolute;
                bottom: 0;
                right: 0;
                background-color: $secondary;
                z-index: -1;
            }
        }
    }

    .block-content {
        text-align: center;

        .input-group {
            input {
                height: 3rem;
                
                @include media-breakpoint-down($main-breakpoint) {
                    width: 100%;
                }
            }

            .btn {
                width: 14rem;
                border-radius: 0;
                border-top-right-radius: $border-top-right-radius;
                transition: opacity 0.3s ease;

                &:hover {
                    opacity: 0.5;
                }
            }

            @include media-breakpoint-down($main-breakpoint) {
                display: block;

                .btn {
                    display: block;
                    height: 3rem;
                    width: 100%;
                    margin-top: 0.5rem;
                }
            }
        }

        div.mage-error {
            position: absolute;
            top: 3rem;

            @include media-breakpoint-down($main-breakpoint) {
                opacity: 0;
            }
        }

        @include media-breakpoint-down($main-breakpoint) {
            margin-bottom: 2rem;
        }
    }
}

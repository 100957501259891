.header-account {
    .text {
        display: none;
    }
}

.customer-account-login,
.customer-account-create,
.customer-account-forgotpassword {
    .page-main {
        padding-bottom: 4rem;
    }

    .page-title-wrapper {
        margin: 4rem 0;
        color: $black;
    }

    .login-container {

        .block-title {
            margin-bottom: 2rem;
            font-size: 2rem;
            font-weight: 400;
        }
    }
}

.navbar-account-buttons {
    padding: 15px;
}

.account-menu-mobile {
    ul {
        padding-left: calc(1rem - 15px);
        list-style: none;

        li {
            display: block;
            padding: 1rem 0;
        }
    }

    @include media-breakpoint-up($main-breakpoint) {
        display: none;
    }
}

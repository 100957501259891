$offcanvas-heading-bg:                  $white;
$offcanvas-heading-color:               $black;
$offcanvas-max-width:                   20rem;
$offcanvas-transition:                  transform .3s ease;

$btn-addtocart:                         $primary;
$btn-cta:                               $primary;

$icon-header__width:                       1.5rem;
$icon-header__height:                      1.5rem;
$icon-footer-width:                        1.8rem;
$icon-footer-height:                       1.8rem;

$logo-max-height: 5.1rem;
$logo-padding: 0;

$nav-background: $secondary;
$nav-text-color: $white;
$nav-text-color-mobile: $black;

$footer-background: $secondary;
$footer-background-desktop: $secondary;
$footer-text-color: $white;
$footer-filter: brightness(0) invert(1);

$home-usp-background-color: rgba($secondary, 0.5);
$home-usp-text-color: $black;
$home-usp-box-shadow: '';

$border-bottom-right-radius: 0;
$border-top-right-radius: 0;

$category-background-color: rgba($secondary, 0.5);
$category-text-color: $black;
$category-text-hover-color: $black;

$pdp-accordion-background-color: rgba($secondary, 0.5);
$pdp-accordion-text-color: $black;
$pdp-accordion-icon-color: $black;

$old-price-text-color: $secondary;
.switcher-language {
    display: block;
    margin-left: 1rem;
    color: $gray-700;

    .switcher-label {
        margin-right: 0.2em;
    }

    .switcher-view {
        cursor: pointer;
        padding: 15px;
        display: block;

        span {
            text-decoration: underline;
        }
    }

    &.dropdown {
        ul {
            padding-left: 0;
            list-style-type: none;

            li {
                padding: 0.5rem 0;
            }
        }

        .dropdown-menu {
            min-width: 14rem;
            border-radius: 0;
            line-height: 1.6;
            border-width: 0;
            text-transform: none;
            padding: 1rem 2rem;

            .dropdown-title {
                padding-bottom: 1rem;
            }

            a {
                display: block;
                color: $black;
                font-size: 1rem;
                font-weight: 400;

                img {
                    margin-right: 0.5rem;
                }

                @include media-breakpoint-down($main-breakpoint) {
                    color: $gray-700;
                }
            }
        }

        @include media-breakpoint-up($main-breakpoint) {
            &:hover {
                .dropdown-menu {
                    display: block;
                    position: absolute;
                    left: 0;
                    padding: 1rem 2rem;
                    border-width: 0;
                    box-shadow: 5px 5px 5px rgba(0,0,0,0.15);
                }
            }
        }
    }



    &#switcher-language-mobile {
        margin-left: 0;

        .dropdown-menu {
            position: static;
            width: 100%;
            left: 0;
            margin-bottom: 1rem;
            padding: 1rem 2rem;
            padding-left: 0;
            border-width: 0;
            border-color: initial;
            box-shadow: initial;
        }
    }
}

.language-mobile-switcher {
    display: none;
    padding: 1rem;
    line-height: 1.5;

    @include media-breakpoint-down(md) {
        display: flex;
    }
}

@import "extend/_variables.scss";
@import "extend/_base.scss";
@import "extend/_helpers.scss";
@import "extend/_layout.scss";

.amblog-grid-list, .amblog-container-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between !important;
    row-gap: 24px;

    .amblog-post-container {
        width: 100%;

        @include media-breakpoint-up($main-breakpoint) {
            width: calc(50% - 1rem);
        }
    }
}

.am-powered-by {
    display: none !important;
}

.amblog-plus {
    & {
        align-items: center;
        cursor: pointer;
        display: inline-flex;
        column-gap: 0.5rem;
    }

    .amblog-icon {
        transition: all .3 ease;
        display: inline-flex;
        height: 18px;
        width: 18px;
    }

    .amblog-count {
        color: $amblog-social-icon__background-color;
    }

    .amblog-icon {
        background: $amblog-like__background;
        background-size: 18px;
        background-position: center;
    }

    &:hover .amblog-icon {
        background: $amblog-like__hover__background;
        background-size: 18px;
        background-position: center;
    }

    &.-voted .amblog-icon {
        background: $amblog-like__hover__background;
        background-size: 18px;
        background-position: center;
    }
}
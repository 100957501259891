//
//  ../fonts
//  _____________________________________________

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), 
         local('Montserrat-Regular'), 
         url('../fonts/Montserrat-Regular.woff2') format('woff2'), 
         url('../fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), 
         local('Montserrat-Medium'), 
         url('../fonts/Montserrat-Medium.woff2') format('woff2'), 
         url('../fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), 
         local('Montserrat-SemiBold'), 
         url('../fonts/Montserrat-SemiBold.woff2') format('woff2'), 
         url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), 
         local('Montserrat-Bold'), 
         url('../fonts/Montserrat-Bold.woff2') format('woff2'), 
         url('../fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

//phpcs:ignoreFile

//
//  Variables
//  _____________________________________________

$amblog-primary__color: #ab8d67;
$amblog-black__color: #1a1a1a;
$amblog-light-blue__color: #85c1ef;
$amblog-blue__color: $amblog-primary__color;
$amblog-gray__color: #333;
$amblog-light-gray__color: #d9d9d9;
$amblog-dark-gray__color: #888;
$amblog-link__hover__color: #185eaf;
$amblog-border__color: #e9e9e9;
$amblog__background-color: #fafafa;
$amblog-date__color: #707070;
$amblog-tag__color: #f5f5f5;
$amblog-input-background: #f9f9f9;
$amblog-input-text__color: #525252;
$amblog-social-icon__background-color: #888;
$amblog-social-icon__hover__background-color: #585858;
$amblog-input__border-color: #e5e5e5;
$amblog-input__hover__border-color: #b3b3b3;
$amblog-svg-custom-classic__color: #499bf8;
$amblog-svg-custom-red__color: #ef5350;
$amblog-svg-custom-green__color: #43a047;
$amblog-svg-custom-blue__color: #3f51b5;
$amblog-svg-custom-grey__color: #bdbdbd;
$amblog-svg-custom-purple__color: #9c27b0;
$amblog-helpful-text__color: #5b5b5b;

// Defaults
$color-white: #ffffff;
$amblog-color__grey: #808080;
$amblog-color__green: #008000;
$amblog-color__red: #ff0000;

//
//  Customizable colors
//  ----------------------------------------------

$amblog-default__background: $amblog__background-color;
$amblog-element__background: $color-white;

$amblog-section-title__color: $amblog-black__color;
$amblog-default-text__color: $amblog-gray__color;
$amblog-placeholder__color: $amblog-date__color;

$amblog-social-icon__background-color: $amblog-dark-gray__color;

//
//  Shadows
//  ----------------------------------------------

$amblog-element__box-shadow: 0 2px 4px rgba(84, 95, 125, .12), 0 5px 8px rgba(155, 159, 174, .1);

//
//  Backgrounds
//  ----------------------------------------------

$amblog-loader__background: url('../Amasty_Blog/images/ajax-loader.gif') no-repeat scroll 50% 0 transparent;

//
//  Indents
//  ----------------------------------------------

$amblog__indent: 10px;
$amblog__indent__l: $amblog__indent * 2 - calc($amblog__indent / 2); // 15px
$amblog__indent__xl: $amblog__indent * 2; // 20px
$amblog__indent__xxl: $amblog__indent + $amblog__indent__l; // 25px

//
//  Paddings & Margins
//  ----------------------------------------------

$amblog-element-default__padding: 25px;
$amblog-element-title__padding: $amblog-element-default__padding;
$amblog-element-content__padding: 0 $amblog-element-default__padding $amblog-element-default__padding;
$amblog-element-sidebar__padding: $amblog-element-default__padding $amblog-element-default__padding calc(#{amblog-element-default__padding} - #{amblog__indent}); /* stylelint-disable-line */

$amblog-page-title-desktop__margin: 20px;
$amblog-page-title-mobile__margin: 5px;

//
//  Sizes
//  ----------------------------------------------

$amblog-font-size__xs: 1.2rem;
$amblog-font-size__s: 1.4rem;
$amblog-font-size__base: 1.6rem;
$amblog-canvas__size: 400px;
$amblog-close__size: 15px;
$amblog-swipe__size: 40px;

//
//  Buttons
//  ----------------------------------------------

$amblog-button__background: $amblog-blue__color;
$amblog-button__default-color: $amblog-button__background;
$amblog-button__border-radius: 3px;
$amblog-button__padding: 12px 30px;
$amblog-button__line-height: 1.8rem;
$amblog-button__font-size: $amblog-font-size__s;
$amblog-button__font-weight: 700;
$amblog-button__transition: .3s ease-in-out;
$amblog-button__border-color: transparent;

$amblog-button__hover__background: $amblog-button__background;
$amblog-button__hover__color: $amblog-button__hover__background;

$amblog-button__active__background: $amblog-button__background, 20%;
$amblog-button__active__color: $amblog-button__active__background;

$amblog-button__color: $amblog-blue__color;


//
//  Z-index
//  ----------------------------------------------

$amblog-z-index__base: 10;
$amblog-z-index__alpha: 1000;
$amblog-z-index__betta: $amblog-z-index__alpha - 1;

//
//  Icons
//  ----------------------------------------------

$amblog-icons-path: '../Amasty_Blog/images/svg';
$amblog-social-icon__size: 40px;
$amblog-swipe__background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiByeD0iMjAiIGZpbGw9IiMxOTc5QzIiLz4KPHJlY3QgeD0iMTEiIHk9IjExIiB3aWR0aD0iOCIgaGVpZ2h0PSI4IiByeD0iMSIgZmlsbD0iI0ZBRkFGQSIvPgo8cmVjdCB4PSIyMSIgeT0iMTEiIHdpZHRoPSI4IiBoZWlnaHQ9IjgiIHJ4PSIxIiBmaWxsPSIjRkFGQUZBIi8+CjxyZWN0IHg9IjExIiB5PSIyMSIgd2lkdGg9IjgiIGhlaWdodD0iOCIgcng9IjEiIGZpbGw9IiNGQUZBRkEiLz4KPHJlY3QgeD0iMjEiIHk9IjIxIiB3aWR0aD0iOCIgaGVpZ2h0PSI4IiByeD0iMSIgZmlsbD0iI0ZBRkFGQSIvPgo8L3N2Zz4K);
$amblog-arrow-mobile__background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE1LjY5NyA1LjM2M2wtNS4xMjYtNS4xYS44ODYuODg2IDAgMCAwLTEuMjYgMCAuODc1Ljg3NSAwIDAgMCAwIDEuMjU0bDMuNjEzIDMuNTk1SC44OTFBLjg5NC44OTQgMCAwIDAgMCA1Ljk5OGMwIC40ODUuNDAzLjg4Ni44OS44ODZoMTIuMDE4TDkuMzEgMTAuNDc5YS44NzUuODc1IDAgMCAwIDAgMS4yNTQuOTIuOTIgMCAwIDAgLjYzOS4yNjcuODguODggMCAwIDAgLjYzOC0uMjY4bDUuMTQzLTUuMTE1QS45MTEuOTExIDAgMCAwIDE2IDUuOThhMS4xMzQgMS4xMzQgMCAwIDAtLjMwMy0uNjE4eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==);
$amblog-arrow-desktop__background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE1LjY5NyA1LjM2M2wtNS4xMjYtNS4xYS44ODYuODg2IDAgMCAwLTEuMjYgMCAuODc1Ljg3NSAwIDAgMCAwIDEuMjU0bDMuNjEzIDMuNTk1SC44OTFBLjg5NC44OTQgMCAwIDAgMCA1Ljk5OGMwIC40ODUuNDAzLjg4Ni44OS44ODZoMTIuMDE4TDkuMzEgMTAuNDc5YS44NzUuODc1IDAgMCAwIDAgMS4yNTQuOTIuOTIgMCAwIDAgLjYzOS4yNjcuODguODggMCAwIDAgLjYzOC0uMjY4bDUuMTQzLTUuMTE1QS45MTEuOTExIDAgMCAwIDE2IDUuOThhMS4xMzQgMS4xMzQgMCAwIDAtLjMwMy0uNjE4eiIgZmlsbD0iIzJENjlBRSIvPjwvc3ZnPg==);

$amblog-dropdown-arrow__background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAyMCAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuODc2NTYgMC40NTQxMDNMMC45MDkwNTggOC44MDA1NkwyLjM0ODc5IDEwLjI1NzNMOS44NzY1NiAzLjI0OTM4TDE3LjY1MTEgMTAuNDU0MUwxOS4wOTA5IDkuMDM2NzhMMTAuNDExMyAwLjkyNjU0NEw5Ljg3NjU2IDAuNDU0MTAzWiIgZmlsbD0iI0M3QzdDNyIvPgo8L3N2Zz4K);

$amblog-post-image__size: 100px;
$amblog-post-image__background-image: url('data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSIgZmlsbD0iI0Q3RDlERCI+PHBhdGggZD0iTTM3LjIzNyAyMy4xOGMtMi4zMjEgMS4xMDUtNC4yMTQgNC40NjItNi40MjggMi4yNDctMS4zNDUtMS40Ni0yLjk0Mi0zLjc4NC00Ljc3Ni02LjQxMi0xLjk4My0yLjgyNS00LjUxMS01LjI1NC03LjQ4Ni0yLjQ3OS0zLjMyMSAzLjEyNC01LjYwMiAxMS42LTUuOCAxNS45NjNsMzEuMzguMDFjLS43MTgtMi41NjMtMy41MDctMTAuOTQtNi44OS05LjMzek0zNy4wOSAxOC4yNThhMy44NTkgMy44NTkgMCAxMDAtNy43MTcgMy44NTkgMy44NTkgMCAwMDAgNy43MTd6Ii8+PHBhdGggZD0iTTQ4Ljg1MyA1LjU5NEg4LjAyM2MtLjYzMyAwLTEuMTQ4LjUxMy0xLjE0OCAxLjE0NnY1LjhIMS4xNDdDLjUxNCAxMi41NCAwIDEzLjA1NCAwIDEzLjY4N3YyOS41N2MwIC42MzQuNTE0IDEuMTQ3IDEuMTQ3IDEuMTQ3aDQwLjgzYy42MzMgMCAxLjE0OC0uNTE0IDEuMTQ4LTEuMTQ3di01Ljc5OWg1LjcyOGMuNjMzIDAgMS4xNDgtLjUxMyAxLjE0OC0xLjE0NlY2Ljc0Yy0uMDAxLS42MzItLjUxNi0xLjE0Ni0xLjE0OC0xLjE0NnptLTguODA3IDM1LjczMkgzLjA3OVYxNS42MmgzLjc5NnYxNy44NzJjLS41NzYgMi4yMjYtLjkzIDQuMzY1LTEuMDAzIDUuOTU2bDMxLjM4LjAxYTM2LjA4MiAzNi4wODIgMCAwMC0uNjMtMmgzLjQyNHYzLjg2OHptNi44NzUtNi45NDdIOS45NTRWOC42NzNoMzYuOTY3VjM0LjM4eiIvPjwvZz48ZGVmcz48Y2xpcFBhdGggaWQ9ImNsaXAwIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMCAwaDUwdjUwSDB6Ii8+PC9jbGlwUGF0aD48L2RlZnM+PC9zdmc+');

$amblog-like__background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBVcGxvYWRlZCB0bzogU1ZHIFJlcG8sIHd3dy5zdmdyZXBvLmNvbSwgR2VuZXJhdG9yOiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4NCjxzdmcgZmlsbD0iIzAwMDAwMCIgaGVpZ2h0PSI4MDBweCIgd2lkdGg9IjgwMHB4IiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiANCgkgdmlld0JveD0iMCAwIDUxMS45OTkgNTExLjk5OSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8Zz4NCgk8Zz4NCgkJPHBhdGggZD0iTTQ3NC42MDYsNzUuMTI3Yy01NS43NTgtNTUuNzU4LTEzNi4zOC00NC40NjEtMTgwLjgzOSwwTDI1NiwxMTIuODkzbC0zNy43NjgtMzcuNzY2DQoJCQljLTQ0LjQ2MS00NC40NjEtMTI1LjA4Mi01NS43NTgtMTgwLjgzOSwwYy00OS44NTgsNDkuODU4LTQ5Ljg1OCwxMzAuOTgyLDAsMTgwLjgzOUwyNTYsNDc0LjU3M2wyMTguNjA3LTIxOC42MDcNCgkJCUM1MjQuNDY0LDIwNi4xMSw1MjQuNDY0LDEyNC45ODUsNDc0LjYwNiw3NS4xMjd6IE00NDMuMzE4LDIyNC42NzhMMjU1Ljk5OSw0MTEuOTk3TDY4LjY4LDIyNC42NzgNCgkJCWMtMzIuNjA1LTMyLjYwNS0zMi42MDUtODUuNjU4LDAtMTE4LjI2M2MzMC43NzUtMzAuNzc1LDgzLjY2Ny0zNC41OTYsMTE4LjI2MywwbDY5LjA1Niw2OS4wNTZsNjkuMDU2LTY5LjA1Ng0KCQkJYzM0LjU5Ni0zNC41OTYsODcuNDg4LTMwLjc3NSwxMTguMjYzLDBDNDc1LjkyMywxMzkuMDIsNDc1LjkyMywxOTIuMDczLDQ0My4zMTgsMjI0LjY3OHoiLz4NCgk8L2c+DQo8L2c+DQo8L3N2Zz4=") no-repeat;
$amblog-like__hover__background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIzIiBoZWlnaHQ9IjI3NyIgdmlld0JveD0iMCAwIDMyMyAyNzciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03NCAxNEw0MCAzMEwxOC41IDU1LjVWMTA3LjVMMTYyLjUgMjU5LjVMMjM4IDE4NC41TDI5OS41IDEyN0wzMTEuNSA2Ny41TDI4OC41IDM0TDI1MSAxNEwyMTIgMjNMMTYyLjUgNjcuNUwxMzguNSA0MUwxMjAgMjNMNzQgMTRaIiBmaWxsPSJibGFjayIvPgo8cGF0aCBkPSJNMjk5LjQxIDI0LjM5NDdDMjY0LjIzNCAtMTAuNzgwOSAyMTMuMzczIC0zLjY1NDAyIDE4NS4zMjYgMjQuMzk0N0wxNjEuNSA0OC4yMTk4TDEzNy42NzQgMjQuMzk0N0MxMDkuNjI1IC0zLjY1NDAyIDU4Ljc2NDUgLTEwLjc4MDkgMjMuNTg5NiAyNC4zOTQ3Qy03Ljg2Mzg1IDU1Ljg0ODEgLTcuODYzODUgMTA3LjAyNiAyMy41ODk2IDEzOC40NzlMMTYxLjUgMjc2LjM4OUwyOTkuNDExIDEzOC40NzlDMzMwLjg2MyAxMDcuMDI3IDMzMC44NjMgNTUuODQ4MSAyOTkuNDEgMjQuMzk0N1pNMjc5LjY3MiAxMTguNzQxTDE2MS40OTkgMjM2LjkxM0w0My4zMjczIDExOC43NDFDMjIuNzU4MSA5OC4xNzEzIDIyLjc1ODEgNjQuNzAyMyA0My4zMjczIDQ0LjEzM0M2Mi43NDIxIDI0LjcxODMgOTYuMTA5NSAyMi4zMDc4IDExNy45MzUgNDQuMTMzTDE2MS40OTkgODcuNjk3N0wyMDUuMDY0IDQ0LjEzM0MyMjYuODg5IDIyLjMwNzggMjYwLjI1NyAyNC43MTgzIDI3OS42NzIgNDQuMTMzQzMwMC4yNDEgNjQuNzAyMyAzMDAuMjQxIDk4LjE3MTMgMjc5LjY3MiAxMTguNzQxWiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==") no-repeat;

$amblog-image-wrapper__background-color: #f4f6f8;
$amblog-image-wrapper__background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIwIiBoZWlnaHQ9IjEyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48bWFzayBpZD0iYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMCIgeT0iMCIgd2lkdGg9IjEyMCIgaGVpZ2h0PSIxMjAiPjxjaXJjbGUgY3g9IjYwIiBjeT0iNjAiIHI9IjYwIiBmaWxsPSIjQUFBIi8+PC9tYXNrPjxnIG1hc2s9InVybCgjYSkiIGZpbGw9IiNEN0Q5REQiPjxwYXRoIGQ9Ik02MC4wMDYgMzJjLTEzLjcyNSAwLTI0Ljk0NSAxMS4yMS0yNC45NDUgMjQuOTI1IDAgMTMuNzE0IDExLjIyIDI0LjkyNSAyNC45NDUgMjQuOTI1czI0Ljk0NS0xMS4yMSAyNC45NDUtMjQuOTI1Qzg0Ljk1MSA0My4yMTEgNzMuNzMxIDMyIDYwLjAwNiAzMnpNMTAyLjgxNiAxMDEuNzdjLS42NTMtMS42MzMtMS41MjUtMy4xNTYtMi41MDUtNC41NzItNS4wMTEtNy40LTEyLjc0NS0xMi4yOTktMjEuNDYtMTMuNDk2LTEuMDg4LS4xMDktMi4yODcuMTA5LTMuMTU4Ljc2Mi00LjU3NSAzLjM3NC0xMC4wMjIgNS4xMTUtMTUuNjg2IDUuMTE1LTUuNjY1IDAtMTEuMTExLTEuNzQxLTE1LjY4Ni01LjExNS0uODcyLS42NTMtMi4wNy0uOTgtMy4xNi0uNzYyYTMwLjc4NCAzMC43ODQgMCAwMC0yMS40NTggMTMuNDk2Yy0uOTggMS40MTYtMS44NTIgMy4wNDgtMi41MDYgNC41NzItLjMyNi42NTMtLjIxOCAxLjQxNS4xMSAyLjA2OC44NyAxLjUyNCAxLjk2IDMuMDQ4IDIuOTQgNC4zNTRhNDEuNDE4IDQxLjQxOCAwIDAwNS4wMTEgNS42NmMxLjUyNSAxLjUyMyAzLjI2OCAyLjkzOCA1LjAxIDQuMzUzIDguNjA2IDYuNDIyIDE4Ljk1NSA5Ljc5NiAyOS42MyA5Ljc5NnMyMS4wMjMtMy4zNzQgMjkuNjI5LTkuNzk2YTQ3Ljk1NyA0Ny45NTcgMCAwMDUuMDEtNC4zNTNjMS43NDQtMS43NDIgMy40ODYtMy41OTIgNS4wMTItNS42NiAxLjA4OS0xLjQxNSAyLjA2OS0yLjgzIDIuOTQxLTQuMzU0LjU0NC0uNjUzLjY1My0xLjQxNS4zMjYtMi4wNjh6Ii8+PC9nPjwvc3ZnPg==');
$amblog-post-image__size: 100px;
$amblog-post-image__background-image: url('data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSIgZmlsbD0iI0Q3RDlERCI+PHBhdGggZD0iTTM3LjIzNyAyMy4xOGMtMi4zMjEgMS4xMDUtNC4yMTQgNC40NjItNi40MjggMi4yNDctMS4zNDUtMS40Ni0yLjk0Mi0zLjc4NC00Ljc3Ni02LjQxMi0xLjk4My0yLjgyNS00LjUxMS01LjI1NC03LjQ4Ni0yLjQ3OS0zLjMyMSAzLjEyNC01LjYwMiAxMS42LTUuOCAxNS45NjNsMzEuMzguMDFjLS43MTgtMi41NjMtMy41MDctMTAuOTQtNi44OS05LjMzek0zNy4wOSAxOC4yNThhMy44NTkgMy44NTkgMCAxMDAtNy43MTcgMy44NTkgMy44NTkgMCAwMDAgNy43MTd6Ii8+PHBhdGggZD0iTTQ4Ljg1MyA1LjU5NEg4LjAyM2MtLjYzMyAwLTEuMTQ4LjUxMy0xLjE0OCAxLjE0NnY1LjhIMS4xNDdDLjUxNCAxMi41NCAwIDEzLjA1NCAwIDEzLjY4N3YyOS41N2MwIC42MzQuNTE0IDEuMTQ3IDEuMTQ3IDEuMTQ3aDQwLjgzYy42MzMgMCAxLjE0OC0uNTE0IDEuMTQ4LTEuMTQ3di01Ljc5OWg1LjcyOGMuNjMzIDAgMS4xNDgtLjUxMyAxLjE0OC0xLjE0NlY2Ljc0Yy0uMDAxLS42MzItLjUxNi0xLjE0Ni0xLjE0OC0xLjE0NnptLTguODA3IDM1LjczMkgzLjA3OVYxNS42MmgzLjc5NnYxNy44NzJjLS41NzYgMi4yMjYtLjkzIDQuMzY1LTEuMDAzIDUuOTU2bDMxLjM4LjAxYTM2LjA4MiAzNi4wODIgMCAwMC0uNjMtMmgzLjQyNHYzLjg2OHptNi44NzUtNi45NDdIOS45NTRWOC42NzNoMzYuOTY3VjM0LjM4eiIvPjwvZz48ZGVmcz48Y2xpcFBhdGggaWQ9ImNsaXAwIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMCAwaDUwdjUwSDB6Ii8+PC9jbGlwUGF0aD48L2RlZnM+PC9zdmc+');

.breadcrumbs-wrapper {
    .breadcrumb {
        text-transform: uppercase;
        font-size: $font-size-sm;

        @include media-breakpoint-down(xs) {
            font-size: 0.8rem;
        }
    }

    a {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -0.1rem;
            width: 100%;
            height: 1px;
            background-color: $black;
            max-width: 0;
            transition: max-width 0.3s ease;
        }

        &:hover {
            text-decoration: none;

            &::after {
                max-width: 100%;
            }
        }
    }
}

.grecaptcha-badge {
    margin-bottom: 1rem;
}

.decorative-font {
    font-family: $decorative-font;
    font-weight: 400;
}

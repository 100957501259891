// // Variables
// //
// // Variables should follow the `$component-state-property-size` formula for
// // consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// // Color system

$white:    #fff;
$gray-100: #f8f8f8;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;
$pink:     #ddcac0;

$primary:           $black;
$secondary:         $pink;

$border-width:                1px;

$font-family-sans-serif:      'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base:            $font-family-sans-serif;
$headings-font-family:        'Montserrat', sans-serif;
$decorative-font:             'Shadows Into Light', cursive;

$input-btn-focus-box-shadow:  none;
$input-btn-border-width:      $border-width;
$btn-focus-box-shadow:        none!important;
$btn-border-radius:           0;
$input-bg:                              $white;
$input-color:                           $black;
$input-border-color:                    $black;
$input-border-width:                    1px;
$input-border-radius:                   0;
$input-focus-bg:                        $white;
$input-focus-border-color:              transparent;
$input-focus-box-shadow:                0px 0px 38px -10px rgba(0,0,0,0.25);

$navbar-light-color:                rgba($black, 1);

$breadcrumb-padding-x:              0;
$breadcrumb-bg:                     $white;
$breadcrumb-divider-color:          $black;
$breadcrumb-active-color:           $black;

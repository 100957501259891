.cms-page-view {
    .page-main {
        a {
            text-decoration: underline;
        }

        .widget-product-grid {

            .product-item {
                margin-bottom: 1.5rem;
            }

            .product-items a {
                text-decoration: initial;
            }

            .product-item-extra {
                font-weight: 200;
            }
        }

        tbody {
            display: block;
            margin: 0 -15px;
            border: none;

            td {
                padding: 0 15px;
                border: none;
            }
        }
    }

    .block-cms-link,
    .block-category-link,
    .block-cms-link-inline,
    .block-category-link-inline,
    .block-product-link,
    .block-product-link-inline {
        a {
            @include btn-primary;
            display: inline-block;
            padding: 1rem 4.5rem;
            margin-bottom: 0.5rem;
            border-bottom-right-radius: $border-bottom-right-radius;
            text-decoration: none;
            text-transform: uppercase;

            &.pink {
                @include button-variant($btn-secondary, $btn-secondary);
                border-bottom-right-radius: 0;
                border-top-left-radius: 2rem;
                border: 2px solid $black;
            }

            &:hover {
                &.pink {
                    border: 2px solid $black;
                }
            }
        }
    }

    .color-match {
        h2 {
            margin-bottom: 2rem;
        }

        a {
            display: block;
            position: relative;
            border-radius: 50%;
            overflow: hidden;
            transition: opacity 0.3s ease;

            &:hover {
                opacity: 0.5;
            }
        }

        .color-match-text {
            padding-bottom: 3rem;
            padding-top: 1rem;
            text-decoration: none;
            text-align: center;

            @include media-breakpoint-down($main-breakpoint) {
                padding-top: 0.5rem;
                padding-bottom: 1.5rem;
            }
        }

        img {
            width: 100%;
        }
    }

    .systemen {
        @include media-breakpoint-up($main-breakpoint) {
            .col-md-6 {
                margin-bottom: 5rem;
            }
        }
    }
}

.page-product-bundle {
    .bundle-options-wrapper {
        display: none;
    }

    .bundle-options-container .product-add-form {
        display: block;
    }

    .block-bundle-summary {
        .title {
            display: none;
        }

        .product-image-container {
            display: none;
        }

        .product.name {
            display: none;
        }
    }

    .price-configured_price {
        display: none;
    }

    .price-container.price-final_price {
        order: 1;
    }

    .page-main .product-info-main .price-box .old-price {
        order: 0;
    }

    .bundle-summary {

        .bundle.items {
            padding-left: 0;
            list-style: none;
        }

        .subtitle {
            display: none;
        }
    }
}

.minicart-items-wrapper {
    .product-item-details {
        .product.options.list {
            .price {
                display: none;
            }
        }
    }
}

.header-minicart {
    position: relative;
    display: inline-flex;

    .icon {
        @include svg-icon(1rem, 1rem, $icon-minicart, $body-color);
    }

    .counter {
        position: absolute;
        left: $cart-counter-desktop-left;
        top: $cart-counter-desktop-top;
        bottom: $cart-counter-desktop-bottom;
        right: $cart-counter-desktop-right;
        width: $cart-counter-desktop-width;
        height: $cart-counter-desktop-height;
        background: $cart-counter-background;
        color: $cart-counter-foreground;
        font-size: $cart-counter-desktop-fontsize;
        text-align: center;
        line-height: $cart-counter-desktop-height;
        border-radius: 50%;
        z-index: 4;
    }
}

.navbar-minicart {
    position: relative;

    .icon {
        @include svg-icon(1rem, 1rem, $icon-minicart-navbar, $body-color);
    }

    .text {
        display: none;
    }

    .counter {
        position: absolute;
        left: $cart-counter-mobile-left;
        top: $cart-counter-mobile-top;
        bottom: $cart-counter-mobile-bottom;
        right: $cart-counter-mobile-right;
        width: $cart-counter-mobile-width;
        height: $cart-counter-mobile-height;
        background: $cart-counter-background;
        color: $cart-counter-foreground;
        font-size: $cart-counter-mobile-fontsize;
        text-align: center;
        line-height: $cart-counter-mobile-height;
        border-radius: 50%;
        z-index: 4;
    }
}

.offcanvas-minicart {
    display: flex;
    flex-flow: column;

    .offcanvas-body {
        overflow-y: auto;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
    }

    .offcanvas-footer {
        padding: 1rem 1rem;
    }

    .block-content {
        >.actions {
            .secondary {
                margin-bottom: 1rem;
            }

            .btn {
                width: 100%;
            }
        }

        .subtotal {
            margin-bottom: 1rem;
            font-weight: $font-weight-bold;

            .amount {
                float: right;
            }
        }
    }

    .minicart-items-wrapper {
        height: 100% !important;
        margin-bottom: 1rem;

        .minicart-items {
            list-style: none;
            padding: 0;

            .product-item {
                .product-row {
                    @include make-row(calc($grid-gutter-width / 2));

                    .product-item-photo {
                        @include make-col-ready(calc($grid-gutter-width / 2));
                        @include make-col(3);
                    }

                    .product-item-details {
                        @include make-col-ready(calc($grid-gutter-width / 2));
                        @include make-col(9);
                    }
                }

                +.product-item {
                    margin-top: 1rem;
                }

                .product-item-pricing {
                    .label {
                        margin-bottom: 0;
                    }

                    .price-container {
                        float: right;
                    }
                }

                .product.options {
                    .toggle {
                        cursor: pointer;

                        span:after {
                            @include svg-icon(1rem, 1rem, $icon-angle-down);
                            margin-left: 0.5rem;
                        }
                    }

                    &.active {
                        .toggle {
                            span:after {
                                @include svg-icon(1rem, 1rem, $icon-angle-up);
                            }
                        }
                    }

                    .content {
                        @include font-size($font-size-sm);

                        .subtitle {
                            display: none;
                        }

                        dl:after {
                            content: '';
                            display: table;
                            clear: both;
                        }

                        dt {
                            clear: left;
                            float: left;
                        }

                        dd {
                            float: left;
                            margin-bottom: 0;
                            margin-left: 0.5rem;
                        }
                    }
                }

                .product.actions {
                    display: flex;
                }
            }
        }
    }
}

.modal-popup {
    &.confirm {
        z-index: 1070 !important;
    }
}

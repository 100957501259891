* {
    &:focus {
        outline: none;
    }
}
img {
    max-width: 100%;
}
@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, lg)) {
    .row:not(.no-gutters) {
        margin-right: calc(-#{$grid-gutter-width} / 4);
        margin-left: calc(-#{$grid-gutter-width} / 4);
    }
    .row:not(.no-gutters) > .col,
    .row:not(.no-gutters) > [class*="col-"] {
        padding-right: calc($grid-gutter-width / 4);
        padding-left: calc($grid-gutter-width / 4);
    }
}
.loading-mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255,255,255,0.5);
    z-index: 1050;

    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        background: rgba(255,255,255,0.5);
    }
}
